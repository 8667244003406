export const GET_ARTIST = 'GET_ARTIST';
export type GET_ARTIST = typeof GET_ARTIST;

export const LOADMORE_ARTIST = 'LOADMORE_ARTIST';
export type LOADMORE_ARTIST = typeof LOADMORE_ARTIST;

export const DETAIL_ARTIST = 'DETAIL_ARTIST';
export type DETAIL_ARTIST = typeof DETAIL_ARTIST;

export const LOADMORE_DETAIL = 'LOADMORE_DETAIL';
export type LOADMORE_DETAIL = typeof LOADMORE_DETAIL;

export const RESET_ARTIST = 'RESET_ARTIST';
export type RESET_ARTIST = typeof RESET_ARTIST;

export const LIST_LIENKHUC = 'LIST_LIENKHUC';
export type LIST_LIENKHUC = typeof LIST_LIENKHUC;

export const LOADMORE_LIENKHUC = 'LOADMORE_LIENKHUC';
export type LOADMORE_LIENKHUC = typeof LOADMORE_LIENKHUC;
