import * as React from "react"
import {
    BrowserRouter, Route, Switch
} from 'react-router-dom'
import Home from './Home'
import Private from './Private'
import NotFound from './NotFound'
// import Play2 from "./Play2";
import Play from "./Play3";
// import Play from "./Play4"
import Category from  './Category'
import Login from "./Login";
import Logout from './Logout'
import ChangePass from "./ChangePass"
import Favorite from "./Favorite";
import Artist from "./Artist";
import ArtistDetail from "./ArtistDetail";
import Package from "./Package";
import PackageApp from "./PackageApp";
import Search from "./Search";
import Rank from "./Rank";
import Tutorial from "./Tutorial";
import Intro from "./Intro";
import IntroApp from "./IntroApp";
import Ctkm from "./Ctkm";
import api from "../api/api";
import {API_PATH} from "../constant";
import * as ls from "local-storage";
import {AuthState} from "../reducer/type/auth";
import StateInterface from "../reducer/type";
import {bindActionCreators, Dispatch} from "redux";
import * as AuthAction from "../action/auth";
import {connect} from "react-redux";
import * as $ from "jquery";
import Lienkhuc from "./Lienkhuc";

interface StatePropsInterface {
    auth: AuthState
}

interface DispatchPropsInterface {
    actions: {
        postAutoLogin: any
    }
}

const mapStateToProps = (state: StateInterface) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        postAutoLogin: AuthAction.autoLoginAction
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface

class App extends React.Component <PropsInterface> {

    constructor (props: PropsInterface) {
        super(props);
    }

    componentWillMount() {
        //this.checkMsisdn();
    }

    checkMsisdn () {
        if (typeof ls.get<string>('token') != 'string') {
            console.log(window.location.pathname);
            let url_string = window.location.href;
            let url = new URL(url_string);
            let link = url.searchParams.get("link");
            if (link == null) {
                api.get(API_PATH + "/service/ecbdecrypt", {
                    params: {
                        'data': 'http://ibolero.vn' + window.location.pathname,
                    }
                }).then((response) => {
                    console.log(response.data);
                    window.location = 'http://free.mobifone.vn/isdn?sp=9355&link=' + response.data as any;
                }).catch((response) => {
                    console.log(response);
                })
            } else {
                if (link != '') {
                    api.get(API_PATH + "/service/ecbdecode", {
                        params: {
                            'data': link,
                        }
                    }).then((response) => {
                        this.props.actions.postAutoLogin(response.data);
                    }).catch((response) => {
                        console.log(response);
                    })
                }
            }
        }
    }

    public render () {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path='/play/:id-:slug' component={Play}/>
                    <Route path='/danh-muc/:id-:slug' component={Category} />
                    <Route exact path='/dang-nhap' component={Login} />
                    <Route exact path='/ca-si' component={Artist} />
                    <Route path='/ca-si/:id-:slug' component={ArtistDetail} />
                    <Route path='/goi-cuoc' component={Package} />
                    <Route path='/app/goi-cuoc' component={PackageApp} />
                    <Route exact path='/bang-xep-hang' component={Rank} />
                    <Route path='/bang-xep-hang/play/:id-:slug' component={Play} />
                    <Route exact path='/tim-kiem/:query' component={Search} />
                    <Route exact path='/tim-kiem' component={Search} />
                    <Route exact path='/huong-dan-su-dung' component={Tutorial} />
                    <Route exact path='/gioi-thieu' component={Intro} />
                    <Route exact path='/app/gioi-thieu' component={IntroApp} />
                    <Route exact path='/ctkm' component={Ctkm} />
                    <Route exact path='/lien-khuc' component={Lienkhuc} />
                    <Private exact path='/dang-xuat' component={Logout} />
                    <Private exact path='/doi-mat-khau' component={ChangePass}/>
                    <Private exact path='/video-yeu-thich' component={Favorite}/>
                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
        )
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(App);
