import * as React from "react"
import {CategoryState} from "../reducer/type/category";
import {ArtistState} from "../reducer/type/artist";
import StateInterface from "../reducer/type";
import {bindActionCreators, Dispatch} from "redux";
import * as CategoryAction from "../action/category";
import * as ArtistAction from "../action/artist";
import {connect} from "react-redux";
import Menu from "../component/Menu";
import Header from "../component/Header";
import {ArtistItemInterface, HomeState} from "../reducer/type/home";
import LoadingScreen from "../component/LoadingScreen";
import LoadMore from "../component/LoadMore";
import { Link } from 'react-router-dom'
import * as AuthAction from "../action/auth";
import * as ls from "local-storage";

interface StatePropsInterface {
    category: CategoryState,
    artist: ArtistState,
    home: HomeState
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        getArtistAction: any
        loadmoreArtistAction: any,
        getInfoAction: any,
    }
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    artist: state.artist,
    home: state.home
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        getArtistAction: ArtistAction.getArtistAction,
        loadmoreArtistAction: ArtistAction.loadmoreArtistAction,
        getInfoAction: AuthAction.getInfoAction,
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface

interface CurrentStateInterface {
    loading: boolean,
    loadmore: boolean
}

class Artist extends React.Component <PropsInterface, CurrentStateInterface> {

    constructor (props: PropsInterface) {
        super(props);
        this.props.actions.getCategoryAction();
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
        this.state = {
            loading: true,
            loadmore: false
        }
        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        if (this.props.artist.data.list.length == 0) {
            this.props.actions.getArtistAction();
        } else {
            this.setState({
                loading: false
            })
        }

        // Scroll
        document.addEventListener('scroll', this.trackScrolling, false);
        document.addEventListener('resize', this.trackScrolling, false);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling, false);
        document.removeEventListener('resize', this.trackScrolling, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.artist.data !== prevProps.artist.data) {
            this.setState({
                loading: false,
                loadmore: false
            })
        }
    }

    trackScrolling () {
        const wrappedElement = document.getElementById('container');
        if ( (wrappedElement.getBoundingClientRect().bottom - 74) <= window.innerHeight ) {
            if (typeof this.props.artist.data.links.next != 'undefined' && this.state.loadmore == false) {
                this.setState({
                    loadmore: true
                });
                this.props.actions.loadmoreArtistAction(this.props.artist.data.links.next);
            }
        }
    }

    handleClickArtist (link) {
        window.location = link
    }

    render() {

        let artistList = this.props.artist.data.list.map((item: ArtistItemInterface, key: number) => {
            return (
                <div className="col-6" key={key}>
                    <div className="pr-0 pl-0">
                        <div onClick={this.handleClickArtist.bind(this, '/ca-si/'+ item.id + '-' + item.slug)} className="content-item">
                            <img className="content-item-singer" src={ item.thumbnail_url }/>
                            <div className="content-item-head text-center">
                                {item.name}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        let loadmoreView;
        if (this.state.loadmore) {
            loadmoreView = (<LoadMore/>)
        }

        return (
            <div id="container" className="container-fluid overflow-hidden">
                <div className="menu-overlay"/>
                <Menu/>
                <Header logo='Ca sĩ'/>
                {loadingScreen}

                <div className="row list-content" style={{
                    marginTop: '0px'
                }}>
                    {artistList}
                    {loadmoreView}
                </div>
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Artist);