export const GET_SONG = 'GET_SONG';
export type GET_SONG = typeof GET_SONG;

export const AUTO_PLAY = 'AUTO_PLAY';
export type AUTO_PLAY = typeof AUTO_PLAY;

export const ADD_FAVORITE = 'ADD_FAVORITE';
export type ADD_FAVORITE = typeof ADD_FAVORITE;

export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export type REMOVE_FAVORITE = typeof REMOVE_FAVORITE;

export const LOADMORE_RELATE = 'LOADMORE_RELATE';
export type LOADMORE_RELATE = typeof LOADMORE_RELATE;

export const GET_VIEWCOUNT = 'GET_VIEWCOUNT';
export type GET_VIEWCOUNT = typeof GET_VIEWCOUNT;