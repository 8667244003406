import * as React from "react"
import {CategoryState} from "../reducer/type/category";
import {AuthState} from "../reducer/type/auth";
import StateInterface from "../reducer/type";
import {bindActionCreators, Dispatch} from "redux";
import * as CategoryAction from "../action/category";
import * as AuthAction from "../action/auth";
import * as $ from "jquery";
import * as ls from "local-storage";
import {Redirect} from "react-router";
import Menu from "../component/Menu";
import Header from "../component/Header";
import {connect} from "react-redux";
import LoadingScreen from "../component/LoadingScreen";

interface StatePropsInterface {
    category: CategoryState,
    auth: AuthState
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        getInfoAction: any,
        changePassAction: any
    }
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    auth: state.auth
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        getInfoAction: AuthAction.getInfoAction,
        changePassAction: AuthAction.changePassAction
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface

interface CurrentState {
    password: string,
    new_password: string,
    re_password: string,
    loading: boolean
}

class ChangePass extends React.Component <PropsInterface, CurrentState> {

    constructor (props: PropsInterface) {
        super(props);
        this.state = {
            password: '',
            new_password: '',
            re_password: '',
            loading: false
        };
        this.props.actions.getCategoryAction();
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $('.login-group').css({
                height: ($(window).height() - 74) + 'px'
            })
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.error != prevProps.auth.error) {
            this.setState({
                loading: false
            })
        }
        if (this.props.auth.data.is_change_pass == "2") {
            alert("Thay đổi mật khẩu thành công");
            window.location = "http://ibolero.vn" as any;
        }
    }

    handlePass(input) {
        this.setState({
            password: input.target.value
        });
    }

    handleNewPass(input) {
        this.setState({
            new_password: input.target.value
        });
    }

    handleRePass(input) {
        this.setState({
            re_password: input.target.value
        });
    }

    handleSubmit () {
        this.setState({
            loading: true
        })
        this.props.actions.changePassAction(this.state.password, this.state.new_password, this.state.re_password)
    }

    render() {
        let showError;
        if (typeof this.props.auth.error != "undefined") {
            if (typeof this.props.auth.error[Object.keys(this.props.auth.error)[0]] != "undefined") {
                showError = (
                    <div className="login-error text-danger mb-3 text-center">
                        { this.props.auth.error[Object.keys(this.props.auth.error)[0]][0] }
                    </div>
                )
            }
        }

        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        return (
            <div className="container-fluid overflow-hidden">
                <div className="menu-overlay"/>
                <Menu/>
                <Header/>
                {loadingScreen}

                <div className="row login-group">
                    <div className="col-12">
                        <div className="login-form">
                            {showError}
                            <input name="old-password" className="form-control" type="password" placeholder="Mật khẩu cũ"
                                   value={this.state.password}
                                   onChange={this.handlePass.bind(this)}/>
                            <input name="new-password" className="form-control" type="password" placeholder="Mật khẩu mới"
                                   value={this.state.new_password}
                                   onChange={this.handleNewPass.bind(this)}/>
                            <input name="re-password" className="form-control" type="password" placeholder="Xác nhận mật khẩu"
                                   value={this.state.re_password}
                                   onChange={this.handleRePass.bind(this)}/>
                            <div className="text-center">
                                <button className="btn login-btn" onClick={this.handleSubmit.bind(this)}>Đổi mật khẩu</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(ChangePass);