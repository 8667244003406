export const POST_LOGIN = 'POST_LOGIN';
export type POST_LOGIN = typeof POST_LOGIN;

export const GET_INFO = 'GET_INFO';
export type GET_INFO = typeof GET_INFO;

export const LOGIN_FAIL = 'LOGIN_FAIL';
export type LOGIN_FAIL = typeof LOGIN_FAIL;

export const POST_LOGOUT = 'POST_LOGOUT';
export type POST_LOGOUT = typeof POST_LOGOUT;

export const CHANGE_PASS = 'CHANGE_PASS';
export type CHANGE_PASS = typeof CHANGE_PASS;

export const CHANGE_PASS_FAIL = 'CHANGE_PASS_FAIL';
export type CHANGE_PASS_FAIL = typeof CHANGE_PASS_FAIL;

export const RESET_ERROR = 'RESET_ERROR';
export type RESET_ERROR = typeof RESET_ERROR;

export const AUTO_LOGIN = 'AUTO_LOGIN';
export type AUTO_LOGIN = typeof AUTO_LOGIN;