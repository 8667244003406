import * as React from "react"
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {CategoryItemInterface, CategoryState} from "../reducer/type/category";
import * as CategoryAction from '../action/category'
import StateInterface from "../reducer/type";
import Menu from "../component/Menu";
import Header from '../component/Header'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import CategoryList from '../component/CategoryList'
import LoadingScreen from "../component/LoadingScreen";
import LoadMore from "../component/LoadMore";
import * as $ from 'jquery'
import * as ls from "local-storage";
import * as AuthAction from "../action/auth";

interface StatePropsInterface {
    category: CategoryState,
}

interface ParamPropsInterface {
    match: {
        params: {
            id: string
        }
    },
    location: any,
    history: any
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        detailCategoryAction: any,
        loadMoreCategoryAction: any,
        resetCategoryAction: any,
        getInfoAction: any,
    }
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        detailCategoryAction: CategoryAction.detailCategoryAction,
        loadMoreCategoryAction: CategoryAction.loadMoreCategoryAction,
        resetCategoryAction: CategoryAction.resetCategoryAction,
        getInfoAction: AuthAction.getInfoAction,
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface & ParamPropsInterface

interface CurrentStateInterface {
    loading: boolean,
    loadmore: boolean
}

class Category extends React.Component <PropsInterface, CurrentStateInterface> {

    unlisten;
    lastScrollTop;

    constructor (props: PropsInterface) {
        super(props);
        this.props.actions.getCategoryAction();
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
        this.state = {
            loading: true,
            loadmore: false
        }
        this.trackScrolling = this.trackScrolling.bind(this)
        this.unlisten = this.props.history.listen((location) => {
            if (location.pathname == '/') {
                this.props.actions.resetCategoryAction();
            }
        });
        this.lastScrollTop = 0;
    }

    componentDidMount() {
        if (this.props.category.detail.list.length == 0) {
            this.props.actions.detailCategoryAction(this.props.match.params.id);
        } else {
            this.setState({
                loading: false
            })
        }

        // Scroll
        document.addEventListener('scroll', this.trackScrolling, false);
        document.addEventListener('resize', this.trackScrolling, false);
    }

    componentWillUnmount() {
        this.unlisten();
        document.removeEventListener('scroll', this.trackScrolling, false);
        document.removeEventListener('resize', this.trackScrolling, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.category.detail !== prevProps.category.detail) {
            this.setState({
                loading: false,
                loadmore: false
            })
        }
        if (this.props.location !== prevProps.location) {
            this.setState({
                loading: true
            });
            this.props.actions.detailCategoryAction(this.props.match.params.id);
        }
    }

    changeCategory (id: string) {
        this.setState({
            loading: true
        });
        this.props.actions.detailCategoryAction(id);
    }

    trackScrolling () {
        const wrappedElement = document.getElementById('container');
        if ( (wrappedElement.getBoundingClientRect().bottom - 74) <= window.innerHeight ) {
            if (typeof this.props.category.detail.links.next != 'undefined' && this.state.loadmore == false) {
                this.setState({
                    loadmore: true
                });
                this.props.actions.loadMoreCategoryAction(this.props.category.detail.links.next);
            }
        }

        let st = window.pageYOffset
        if (st > this.lastScrollTop){
            $('.category-group').css({
                top: 0
            });
        } else {
            $('.category-group').css({
                top: '74px'
            });
        }
        this.lastScrollTop = st <= 0 ? 0 : st;
    }

    render() {

        let slideData = this.props.category.data.map((item: CategoryItemInterface, key: number) => {
            let categoryActive = '';
            if (item.id == this.props.category.detail.id) {
                categoryActive = ' category-active';
            }

            return (
                <div className={"category-slide-item" + categoryActive} key={key}>
                    <span onClick={this.changeCategory.bind(this, item.id)}>{ item.name }</span>
                </div>
            );

        });


        let positionCate;
        positionCate = this.props.category.data.findIndex((item: CategoryItemInterface, key: number) => {
            //console.log(this.props.category.detail.id);
            //console.log(item.id);
            return item.id == this.props.category.detail.id;
        });
        //console.log(positionCate);

        let settings = {
            infinite: true,
            variableWidth: true,
            centerMode: true,
            swipeToSlide: true,
            initialSlide: positionCate
        };

        let sliderContent;
        if (positionCate > -1) {
            sliderContent = (
                <Slider {...settings} className="category-slide">
                    {slideData}
                </Slider>
            );
        }

        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        let loadmoreView;
        if (this.state.loadmore) {
            loadmoreView = (<LoadMore/>)
        }

        return (
            <div id="container" className="container-fluid overflow-hidden">
                <div className="menu-overlay"/>
                <Menu/>
                <Header/>
                {loadingScreen}

                <div className="category-group">
                    <div className="col-12">
                        {sliderContent}
                    </div>
                </div>
                <div className="row list-content" style={{
                    marginTop: '45px'
                }}>
                    <CategoryList video={this.props.category.detail.list}/>
                    {loadmoreView}
                </div>
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(Category);