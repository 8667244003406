export const INFO_HOME = 'INFO_HOME';
export type INFO_HOME = typeof INFO_HOME;

export const INFO_ARTIST = 'INFO_ARTIST';
export type INFO_ARTIST = typeof INFO_ARTIST;

export const INFO_BANNER = 'INFO_BANNER';
export type INFO_BANNER = typeof INFO_BANNER;

export const INFO_LIENKHUC = 'INFO_LIENKHUC';
export type INFO_LIENKHUC = typeof INFO_LIENKHUC;

export const INFO_RANK = 'INFO_RANK';
export type INFO_RANK = typeof INFO_RANK;

export const SEARCH_DATA = 'SEARCH_DATA';
export type SEARCH_DATA = typeof SEARCH_DATA;

export const LOADMORE_SEARCH = 'LOADMORE_SEARCH';
export type LOADMORE_SEARCH = typeof LOADMORE_SEARCH;
