export const GET_CATEGORY = 'GET_CATEGORY';
export type GET_CATEGORY = typeof GET_CATEGORY;

export const GET_RANK = 'GET_RANK';
export type GET_RANK = typeof GET_RANK;

export const DETAIL_CATEGORY = 'DETAIL_CATEGORY';
export type DETAIL_CATEGORY = typeof DETAIL_CATEGORY;

export const LOAD_MORE_CATEGORY = 'LOAD_MORE_CATEGORY';
export type LOAD_MORE_CATEGORY = typeof LOAD_MORE_CATEGORY;

export const LIST_FAVORITE = 'LIST_FAVORITE';
export type LIST_FAVORITE = typeof LIST_FAVORITE;

export const REMOVE_LIST_FAVORITE = 'REMOVE_LIST_FAVORITE';
export type REMOVE_LIST_FAVORITE = typeof REMOVE_LIST_FAVORITE;

export const LOADMORE_LIST_FAVORITE = 'LOADMORE_LIST_FAVORITE';
export type LOADMORE_LIST_FAVORITE = typeof LOADMORE_LIST_FAVORITE;

export const RESET_CATEGORY = 'RESET_CATEGORY';
export type RESET_CATEGORY = typeof RESET_CATEGORY;