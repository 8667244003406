import * as React from "react"
import {CategoryItemInterface, CategoryState} from "../reducer/type/category";
import {ArtistState} from "../reducer/type/artist";
import StateInterface from "../reducer/type";
import {bindActionCreators, Dispatch} from "redux";
import * as CategoryAction from "../action/category";
import * as ArtistAction from "../action/artist";
import {connect} from "react-redux";
import Menu from "../component/Menu";
import Header from "../component/Header";
import LoadingScreen from "../component/LoadingScreen";
import LoadMore from "../component/LoadMore";
import Slider from "react-slick";
import CategoryList from "../component/CategoryList";
import * as $ from 'jquery'
import {HomeState} from "../reducer/type/home";
import * as HomeAction from "../action/home";
import * as ls from "local-storage";
import * as AuthAction from "../action/auth";

interface StatePropsInterface {
    category: CategoryState,
    artist: ArtistState,
    home: HomeState
}

interface ParamPropsInterface {
    match: {
        params: {
            id: string
        }
    },
    location: any,
    history: any
}

interface DispatchPropsInterface {
    actions: {
        getCategoryAction: any,
        detailArtistAction: any,
        loadmoreDetailAction: any,
        resetArtistAction: any,
        getInfoAction: any,
    }
}

const mapStateToProps = (state: StateInterface) => ({
    category: state.category,
    artist: state.artist,
    home: state.home
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators({
        getCategoryAction: CategoryAction.getCategoryAction,
        detailArtistAction: ArtistAction.detailArtistAction,
        loadmoreDetailAction: ArtistAction.loadmoreDetailAction,
        resetArtistAction: ArtistAction.resetArtistAction,
        getInfoAction: AuthAction.getInfoAction,
    }, dispatch)
});

type PropsInterface = StatePropsInterface & DispatchPropsInterface & ParamPropsInterface

interface CurrentStateInterface {
    loading: boolean,
    loadmore: boolean
}

class ArtistDetail extends React.Component <PropsInterface, CurrentStateInterface> {

    //unlisten;
    lastScrollTop;

    constructor (props: PropsInterface) {
        super(props);
        this.props.actions.getCategoryAction();
        if (typeof ls.get<string>('token') == 'string') {
            this.props.actions.getInfoAction();
        }
        this.state = {
            loading: true,
            loadmore: false
        }
        this.trackScrolling = this.trackScrolling.bind(this)
        // this.unlisten = this.props.history.listen((location) => {
        //     if (location.pathname == '/ca-si' || location.pathname == '/') {
        //         this.props.actions.resetArtistAction();
        //     }
        // });
        this.lastScrollTop = 0;
    }

    componentDidMount() {
        if (this.props.artist.detail.list_song.list.length == 0) {
            this.props.actions.detailArtistAction(this.props.match.params.id);
        } else {
            this.setState({
                loading: false
            })
        }

        // let self = this
        // $(window).on('popstate', function() {
        //     self.props.actions.resetArtistAction();
        // });

        // Scroll
        document.addEventListener('scroll', this.trackScrolling, false);
        document.addEventListener('resize', this.trackScrolling, false);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling, false);
        document.removeEventListener('resize', this.trackScrolling, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.artist.detail !== prevProps.artist.detail) {
            this.setState({
                loading: false,
                loadmore: false
            })
        }
        if (this.props.location !== prevProps.location) {
            this.setState({
                loading: true
            });
            this.props.actions.detailArtistAction(this.props.match.params.id);
        }
    }

    trackScrolling () {
        const wrappedElement = document.getElementById('container');
        if ( (wrappedElement.getBoundingClientRect().bottom - 74) <= window.innerHeight ) {
            if (typeof this.props.artist.detail.list_song.links.next != 'undefined' && this.state.loadmore == false) {
                this.setState({
                    loadmore: true
                });
                this.props.actions.loadmoreDetailAction(this.props.artist.detail.list_song.links.next);
            }
        }

        let st = window.pageYOffset
        if (st > this.lastScrollTop){
            $('.category-group').css({
                top: 0
            });
        } else {
            $('.category-group').css({
                top: '74px'
            });
        }
        this.lastScrollTop = st <= 0 ? 0 : st;
    }

    changeArtist (id: string) {
        this.setState({
            loading: true
        });
        this.props.actions.detailArtistAction(id);
    }

    render() {
        let settings = {
            infinite: false,
            variableWidth: true,
            slidesToScroll: 2
        };

        let loadingScreen;
        if (this.state.loading) {
            loadingScreen = (<LoadingScreen/>)
        }

        let loadmoreView;
        if (this.state.loadmore) {
            loadmoreView = (<LoadMore/>)
        }

        // let slideData = this.props.artist.detail.relate_artist.map((item: CategoryItemInterface, key: number) => {
        //     let artistActive = '';
        //     if (item.id == this.props.artist.detail.id) {
        //         artistActive = ' category-active';
        //     }
        //
        //     return (
        //         <div className={"category-slide-item" + artistActive} key={key}>
        //             <span onClick={this.changeArtist.bind(this, item.id)}>{ item.name }</span>
        //         </div>
        //     );
        //
        // });

        return (
            <div id="container" className="container-fluid overflow-hidden">
                <div className="menu-overlay"/>
                <Menu/>
                <Header logo={this.props.artist.detail.name}/>
                {loadingScreen}

                {/*<div className="row category-group" style={{left: 'initial'}}>*/}
                {/*    <div className="col-12">*/}
                {/*        <Slider {...settings} className="category-slide">*/}
                {/*            {slideData}*/}
                {/*        </Slider>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="row list-content" style={{
                    marginTop: '0px'
                }}>
                    <CategoryList video={this.props.artist.detail.list_song.list}/>
                    {loadmoreView}
                </div>
            </div>
        );
    }

}

export default connect<StatePropsInterface, DispatchPropsInterface>(
    mapStateToProps,
    mapDispatchToProps
)(ArtistDetail);